import { EVENTS } from '~/constants/events';
import { generateState } from '~/utils/tokens';
import { ssoState } from '~/constants/storage';

export const useCarbManagerSSO = () => {
  const { $trackEvent, $getVariant } = useNuxtApp();
  const config = useRuntimeConfig();
  const route = useRoute();
  const { FLAG_CM_SSO } = flagsConfig;

  const featureFlagVariant = ref<string | undefined>($getVariant(FLAG_CM_SSO));
  const ssoLoading = ref(true);
  const ssoError = ref<string | null>(null);

  const shouldShowCarbManagerSSO = computed(() => {
    return featureFlagVariant.value === 'on';
  });

  const onClickLoginCarbManager = () => {
    $trackEvent(EVENTS.LOGIN_CARB_MANAGER_SSO_CLICKED);
    const state = generateState(32);
    ssoState.value = state;

    // Get the current URL and remove the query parameters
    // This is so CM can redirect back to this page once authenticated
    const url = new URL(window.location.href);
    const baseUrl = `${url.protocol}//${url.host}${url.pathname}`;

    const queryParams = {
      state,
      redirect_uri: baseUrl,
    };

    // Redirect to Carb Manager SSO
    window.location.href = `${config.public.carbManagerUrl}/account/signin/taste-network?${new URLSearchParams(queryParams).toString()}`;
  };

  const verifySSOToken = async () => {
    // Retrieve the token from the query parameter
    const token = route.query.sso_token as string;

    if (!token || !ssoState.value) {
      ssoLoading.value = false;
      return false;
    }

    try {
      ssoLoading.value = true;
      const data = await $fetch('/api/sso/verify-token', {
        method: 'POST',
        body: {
          token,
          state: ssoState.value,
        },
      });

      if (!data.isValid || !data.signInToken) {
        throw new Error('Invalid or expired token');
      }

      await customTokenLogin(data.signInToken);
      return true;
    } catch (err: any) {
      ssoError.value = 'Invalid or expired token. Please try again.';
      ssoLoading.value = false;
      ssoState.value = '';
      return false;
    }
  };

  return {
    verifySSOToken,
    shouldShowCarbManagerSSO,
    onClickLoginCarbManager,
    ssoError,
    ssoLoading,
  };
};
